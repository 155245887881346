import React from "react";

const ThemeSuspense = () => {
  return (
    <div className="grid ">
      <center className="w-full h-full pt-50 text-xl justify-center items-center ">
        <div className="div-load "> </div>
        {/* Cargando... */}
      </center>
    </div>
  );
};

export default ThemeSuspense;
