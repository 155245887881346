import React, { lazy, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import IdleTimeOutHandler from "./components/session/IdleTimeOutHandler";
import { AdminContext } from "./context/AdminContext";
import Cookies from "js-cookie";

const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
window.palabra_busqueda = "";

// const Dashboard = lazy(() => import("./pages/Dashboard"));

const App = () => {
  const { state, dispatch } = useContext(AdminContext);
  const [isActive, setIsActive] = useState(true);
  return (
    <>
      <ToastContainer />
      <IdleTimeOutHandler
        onActive={() => {
          setIsActive(true);
        }}
        onIdle={() => {
          setIsActive(false);
        }}
      />

      <Router>
        <AccessibleNavigationAnnouncer />
        {isActive ? (
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/reset-password/:token" component={ResetPassword} />

            <PrivateRoute>
              {" "}
              <Route path="/" component={Layout} />
            </PrivateRoute>
            <Redirect exact from="/" to="/login" />
          </Switch>
        ) : (
          (dispatch({ type: "USER_LOGOUT" }),
          Cookies.remove("adminInfo"),
          Cookies.remove("materias_data"),
          Cookies.remove("grupos"),
          Cookies.remove("searching_mode"),
          localStorage.removeItem("lista_titulos_buscar"),
          localStorage.removeItem("idioma_seleccionado"),
          localStorage.removeItem("grado_id"),
          localStorage.removeItem("title_id"),
          localStorage.removeItem("items_lista_materias"),
          localStorage.removeItem("title_id_back"),
          localStorage.removeItem("items_titulos_conteo"),
          localStorage.removeItem("permisos_data"),
          localStorage.removeItem("data_title_filter"),
          localStorage.removeItem("usuario_imagen"),
          localStorage.removeItem("title_search_text"),
          localStorage.removeItem("title_search_text_temp"),
          localStorage.removeItem("imagen_seleccionada"),
          localStorage.removeItem("materia_id"),
          localStorage.removeItem("colegio_actual"),
          localStorage.removeItem("items_titulos_conteo_grado"),
          localStorage.removeItem("grupo_id_selected"),
          localStorage.removeItem("items_titulos_conteo_grado_en"),
          localStorage.removeItem("title_materia_search"),
          setTimeout(window.location.reload(), 2000))
        )}
      </Router>
    </>
  );
};

export default App;
