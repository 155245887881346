import Cookies from 'js-cookie';
import React, { createContext, useReducer } from 'react';

export const SearchingContext = createContext();

const initialState = {
  searchMode: Cookies.get('searching_mode') ? JSON.parse(Cookies.get('searching_mode')) : false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SEARCH_MODE_ON':
      return { ...state, searchMode: action.payload};

    case 'SEARCH_MODE_OFF':
      return {
        ...state,
        searchMode: null,
      };

    default:
      return state;
  }
}

export const SearchingProvider = ({ children }) => {
  const [stateSearch, dispatchSearch] = useReducer(reducer, initialState);
  const value = { stateSearch, dispatchSearch };
  return (
    <SearchingContext.Provider value={value}>{children}</SearchingContext.Provider>
  );
};